/*! js-cookie v3.0.0 | MIT */
;
(function (global, factory) {
  typeof exports === 'object' && typeof module !== 'undefined' ? module.exports = factory() :
  typeof define === 'function' && define.amd ? define(factory) :
  (global = global || self, (function () {
    var current = global.Cookies;
    var exports = global.Cookies = factory();
    exports.noConflict = function () { global.Cookies = current; return exports; };
  }()));
}(this, (function () { 'use strict';

  /* eslint-disable no-var */
  function assign (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];
      for (var key in source) {
        target[key] = source[key];
      }
    }
    return target
  }
  /* eslint-enable no-var */

  /* eslint-disable no-var */
  var defaultConverter = {
    read: function (value) {
      return value.replace(/(%[\dA-F]{2})+/gi, decodeURIComponent)
    },
    write: function (value) {
      return encodeURIComponent(value).replace(
        /%(2[346BF]|3[AC-F]|40|5[BDE]|60|7[BCD])/g,
        decodeURIComponent
      )
    }
  };
  /* eslint-enable no-var */

  /* eslint-disable no-var */

  function init (converter, defaultAttributes) {
    function set (key, value, attributes) {
      if (typeof document === 'undefined') {
        return
      }

      attributes = assign({}, defaultAttributes, attributes);

      if (typeof attributes.expires === 'number') {
        attributes.expires = new Date(Date.now() + attributes.expires * 864e5);
      }
      if (attributes.expires) {
        attributes.expires = attributes.expires.toUTCString();
      }

      key = encodeURIComponent(key)
        .replace(/%(2[346B]|5E|60|7C)/g, decodeURIComponent)
        .replace(/[()]/g, escape);

      value = converter.write(value, key);

      var stringifiedAttributes = '';
      for (var attributeName in attributes) {
        if (!attributes[attributeName]) {
          continue
        }

        stringifiedAttributes += '; ' + attributeName;

        if (attributes[attributeName] === true) {
          continue
        }

        // Considers RFC 6265 section 5.2:
        // ...
        // 3.  If the remaining unparsed-attributes contains a %x3B (";")
        //     character:
        // Consume the characters of the unparsed-attributes up to,
        // not including, the first %x3B (";") character.
        // ...
        stringifiedAttributes += '=' + attributes[attributeName].split(';')[0];
      }

      return (document.cookie = key + '=' + value + stringifiedAttributes)
    }

    function get (key) {
      if (typeof document === 'undefined' || (arguments.length && !key)) {
        return
      }

      // To prevent the for loop in the first place assign an empty array
      // in case there are no cookies at all.
      var cookies = document.cookie ? document.cookie.split('; ') : [];
      var jar = {};
      for (var i = 0; i < cookies.length; i++) {
        var parts = cookies[i].split('=');
        var value = parts.slice(1).join('=');

        if (value[0] === '"') {
          value = value.slice(1, -1);
        }

        try {
          var foundKey = defaultConverter.read(parts[0]);
          jar[foundKey] = converter.read(value, foundKey);

          if (key === foundKey) {
            break
          }
        } catch (e) {}
      }

      return key ? jar[key] : jar
    }

    return Object.create(
      {
        set: set,
        get: get,
        remove: function (key, attributes) {
          set(
            key,
            '',
            assign({}, attributes, {
              expires: -1
            })
          );
        },
        withAttributes: function (attributes) {
          return init(this.converter, assign({}, this.attributes, attributes))
        },
        withConverter: function (converter) {
          return init(assign({}, this.converter, converter), this.attributes)
        }
      },
      {
        attributes: { value: Object.freeze(defaultAttributes) },
        converter: { value: Object.freeze(converter) }
      }
    )
  }

  var api = init(defaultConverter, { path: '/' });
  /* eslint-enable no-var */

  return api;

})));

(function ($) {
 /**
  * Cookie Banner
  */
 
 /*
  Connections:
  -------------------------------------------------------------------------------
  * google - GTM code
  * gads - Google Ads Remarketing
  * hotjar - HotJar
  * fb - Facebook pixel
  * maps - Google Maps
  * players - every player on website: Youtube, Slideshare
  * tiktok - TikTok pixel
  * linkedin - Linkedin pixel
  * smarts - Smartsupp
 */
 
 class NZ_CookieBanner {
     constructor() {
       this.config = {
         debugMode: true,
         userConsents: {
           google: false,
           hotjar: false,
           fb: false,
           maps: false,
           players: false,
           gads: false,
           tiktok: false,
           linkedin: false ,
           smarts: false
         },
         groups: [
           'google',
           'hotjar',
           'fb',
           'maps',
           'players',
           'gads',
           'tiktok',
           'linkedin',
           'smarts'
         ],
         allowedGroups: []
       };
 
       this.UI = {
         basicWindow: $('.nz-cn-info'),
         extendedWindow: $('.nz-cn-info-extended'),
         buttons: {
           basicShowExtended: $('.nz-cn-info-button-show-extended'),
           basicAcceptAll: $('.nz-cn-info-button-accept-all'),
           basicRejectAll: $('.nz-cn-info-button-reject-all'),
           extendedAcceptAll: $('.nz-cn-info-extended-button-accept-all'),
           extendedSaveConfig: $('.nz-cn-info-extended-button-save-consent'),
           extendedNotNow: $('.nz-cn-info-extended-button-close-without-save')
         },
         checkboxes: {
           google: $('.nz-cn-consent-checkbox[data-consent-type="google"]'),
           hotjar: $('.nz-cn-consent-checkbox[data-consent-type="hotjar"]'),
           fb: $('.nz-cn-consent-checkbox[data-consent-type="fb"]'),
           maps: $('.nz-cn-consent-checkbox[data-consent-type="maps"]'),
           players: $('.nz-cn-consent-checkbox[data-consent-type="players"]'),
           gads: $('.nz-cn-consent-checkbox[data-consent-type="gads"]'),
           tiktok: $('.nz-cn-consent-checkbox[data-consent-type="tiktok"]'),
           linkedin: $('.nz-cn-consent-checkbox[data-consent-type="linkedin"]'),
           smarts: $('.nz-cn-consent-checkbox[data-consent-type="smarts"]'),
         }
       };
 
       if (this.UI.basicWindow.length) {
         this.initEvents();
         this.initPublicAPI();
         this.checkConfig();
 
         setTimeout(() => {
           this.checkMap()
         }, 150);
       }
     }
 
     checkMap() {
         if (this.config.userConsents.maps === true) {
             if (document.querySelectorAll('.map').length > 0) {
                 document.querySelectorAll('.map').forEach(map => {
                     map.classList.remove('is-hidden');
                 })
             }
         }
     }
 
     checkConfig () {
       let config = Cookies.get('nz-cn-config-v2');
 
       // When no config - show popup
       if (!config) {
         if (this.config.debugMode) {
           console.log('⛔️ Consent config not found - showing popup');
         }
 
         this.showPopup();
         return;
       }
 
       // otherwise - load config without showing popup
       this.loadConfig(config);
     }
 
     loadConfig (configFromCookie) {
       this.config.userConsents.google = configFromCookie.indexOf('google') > -1;
       this.config.userConsents.hotjar = configFromCookie.indexOf('hotjar') > -1;
       this.config.userConsents.fb = configFromCookie.indexOf('fb') > -1;
       this.config.userConsents.maps = configFromCookie.indexOf('maps') > -1;
       this.config.userConsents.players = configFromCookie.indexOf('players') > -1;
       this.config.userConsents.gads = configFromCookie.indexOf('gads') > -1;
       this.config.userConsents.tiktok = configFromCookie.indexOf('tiktok') > -1;
       this.config.userConsents.linkedin = configFromCookie.indexOf('linkedin') > -1;
       this.config.userConsents.smarts = configFromCookie.indexOf('smarts') > -1;
 
       for (let i = 0; i < this.config.groups.length; i++) {
         let groupName = this.config.groups[i];
 
         if (this.config.userConsents[groupName]) {
             this.config.allowedGroups.push(groupName);
         }
       }
 
       if (this.config.debugMode) {
         console.log('⚙️ Founded config:', JSON.parse(JSON.stringify(this.config.userConsents)));
         this.checkMap()
       }
     }
 
     initEvents () {
       $(document).find('a[href="#cookie-settings"]').on('click', e => {
         e.preventDefault();
         this.showExtendedPopup();
       });
 
       this.UI.buttons.basicShowExtended.on('click', e => {
         e.preventDefault();
         this.closePopup();
         this.showExtendedPopup();
         this.checkMap();
       });
 
       this.UI.buttons.basicAcceptAll.on('click', e => {
         e.preventDefault();
         this.giveConsentForAll();
         this.closePopup();
         this.checkMap();
       });
 
       this.UI.buttons.basicRejectAll.on('click', e => {
         e.preventDefault();
         this.resetCookieConfig();
         this.closePopup();
         this.checkMap();
       });
 
       this.UI.buttons.extendedAcceptAll.on('click', e => {
         e.preventDefault();
         this.giveConsentForAll();
         this.closeExtendedPopup();
         this.checkMap();
       });
 
       this.UI.buttons.extendedSaveConfig.on('click', e => {
         e.preventDefault();
         this.saveConsent();
         this.closeExtendedPopup();
         this.checkMap();
       });
 
       this.UI.buttons.extendedNotNow.on('click', e => {
         e.preventDefault();
         this.closeExtendedPopup();
       });
     }
 
     setCheckboxes () {
       for (let i = 0; i < this.config.groups.length; i++) {
         let group = this.config.groups[i];
 
         if (this.config.userConsents[group]) {
           this.UI.checkboxes[group].prop('checked', true);
         } else {
           this.UI.checkboxes[group].prop('checked', false);
         }
       }
     }
 
     initPublicAPI () {
       window.nzCookieBanner = {
         giveConsentAndSave: (type) => this.giveConsentAndSave(type),
         giveConsentAndSaveAndLoad: (type) => this.giveConsentAndSave(type, true),
         showExtendedPopup: () => this.showExtendedPopup,
         getConsentStatus: (type) => this.getConsentStatus(type)
       };
     }
 
     showPopup () {
       this.UI.basicWindow.removeClass('is-hidden');
     }
 
     closePopup () {
       this.UI.basicWindow.addClass('is-hidden');
     }
 
     showExtendedPopup () {
       this.UI.extendedWindow.removeClass('is-hidden');
       $(document.body).addClass('no-scroll');
       this.setCheckboxes();
     }
 
     closeExtendedPopup () {
       this.UI.extendedWindow.addClass('is-hidden');
       $(document.body).removeClass('no-scroll');
     }
 
     giveConsentForAll () {
       this.giveConsent('google');
       this.giveConsent('hotjar');
       this.giveConsent('fb');
       this.giveConsent('maps');
       this.giveConsent('players');
       this.giveConsent('gads');
       this.giveConsent('tiktok');
       this.giveConsent('linkedin');
       this.giveConsent('smarts');
       this.saveConsentConfigInCookie();
     }
 
     giveConsent (type, forceOnLoad = false) {
       if (this.config.debugMode) {
         console.log('✅ User gave consent:', type);
       }
 
       // if consent has been previously given - do nothing
       if (this.config.allowedGroups.indexOf(type) > -1) {
         return;
       }
 
       this.config.userConsents[type] = true;
 
       if (type === 'google') {
         this.allowGoogle();
         return;
       }
 
       if (type === 'gads') {
         this.allowGads();
         return;
       }
 
       if (type === 'hotjar') {
         this.allowHotJar();
         return;
       }
 
       if (type === 'fb') {
         this.allowFB();
         return;
       }
 
       if (type === 'maps') {
         this.allowMaps();
         return;
       }
 
       if (type === 'players') {
         this.allowPlayers();
         return;
       }
 
       if (type === 'tiktok') {
         this.allowTiktok();
         return;
       }
 
       if (type === 'smarts') {
         this.allowSmarts();
         return;
       }
     }
 
     giveConsentAndSave (type, forceOnLoad = false) {
       this.giveConsent(type, forceOnLoad);
       this.saveConsentConfigInCookie();
     }
 
     saveConsent () {
       let consentCompare = this.compareConsents();
       this.setConsentBasedOnCheckboxes();
 
       // if some consent has been removed
       if (consentCompare === 'some-rejected') {
         this.saveConsentConfigInCookie();
         window.location.reload();
         return;
       }
 
       // otherwise - if some consent has been added
       this.saveConsentConfigInCookie();
     }
 
     setConsentBasedOnCheckboxes () {
       for (let i = 0; i < this.config.groups.length; i++) {
         let group = this.config.groups[i];
 
         if (!!this.UI.checkboxes[group].prop('checked')) {
           this.giveConsent(group);
         }
 
         this.config.userConsents[group] = !!this.UI.checkboxes[group].prop('checked');
       }
     }
 
     saveConsentConfigInCookie () {
       let configToSave = [];
 
       for (let i = 0; i < this.config.groups.length; i++) {
         let group = this.config.groups[i];
 
         if (this.config.userConsents[group]) {
           configToSave.push(group);
         }
       }
 
       if (configToSave.length) {
         Cookies.set('nz-cn-config-v2', configToSave.join(';'), { expires: 180 });
 
         if (this.config.debugMode) {
           console.log('🍪 Consent config saved in the cookie');
         }
       } else {
         this.resetCookieConfig();
       }
     }
 
     resetCookieConfig () {
       Cookies.set('nz-cn-config-v2', 'null', { expires: 180 });
 
       if (this.config.debugMode) {
         console.log('🗑 User rejected all cookies - save empty config cookie');
       }
     }
 
     compareConsents () {
       let someRejected = false;
 
       for (let i = 0; i < this.config.groups.length; i++) {
         let group = this.config.groups[i];
 
         if (this.config.userConsents[group] && !this.UI.checkboxes[group].prop('checked')) {
           someRejected = true;
         }
       }
 
       if (someRejected) {
         if (this.config.debugMode) {
           console.log('🔄 Some consents has been rejected - website reload needed');
         }
 
         return 'some-rejected';
       }
 
       if (this.config.debugMode) {
         console.log('ℹ️ User not rejected any consents - website reload not needed');
       }
 
       return 'some-added-or-not-changed';
     }
 
     getConsentStatus (type) {
       return !!this.config.userConsents[type];
     }
 
     allowGoogle () {
       if (window.nzUnlocked.indexOf('google') > -1) {
         return;
       }
 
       window.nzUnlockConsentedScript('google', 'head', true);
     }
 
     allowGads () {
         if (window.nzUnlocked.indexOf('gads') > -1) {
           return;
         }
 
         window.nzUnlockConsentedScript('gads', 'head', true);
     }
 
     allowHotJar () {
         if (window.nzUnlocked.indexOf('hotjar') > -1) {
           return;
         }
 
         window.nzUnlockConsentedScript('hotjar', 'head', true);
     }
 
     allowFB () {
         if (window.nzUnlocked.indexOf('fb') > -1) {
           return;
         }
 
         window.nzUnlockConsentedScript('fb', 'head', true);
     }
 
     allowMaps() {
         if (window.nzUnlocked.indexOf('maps') > -1) {
             return;
         }
 
         window.nzUnlockMaps(true);
         window.nzUnlockConsentedScript('maps', 'body', true);
     }
 
     allowPlayers() {
         if (window.nzUnlocked.indexOf('players') > -1) {
             return;
         }
 
         window.nzUnlockPlayers(true);
     }
 
     allowTiktok () {
         if (window.nzUnlocked.indexOf('tiktok') > -1) {
           return;
         }
 
         window.nzUnlockConsentedScript('tiktok', 'head', true);
     }
 
     allowSmarts () {
         if (window.nzUnlocked.indexOf('smarts') > -1) {
             return;
         }
 
         window.nzUnlockConsentedScript('smarts', 'body', true);
     }
 }
 

 new NZ_CookieBanner();
})(jQuery);

function consentOpen() {
 let consentButton = document.querySelectorAll('.open_form_content');
 if(!consentButton.length) {
  return
 }
 let consentContent = document.querySelectorAll('.consent_hidden');

 for (let i = 0; i < consentButton.length; i++) {
  consentButton[i].addEventListener('click', function(e) {
   e.preventDefault();
   consentContent[i].classList.toggle('active');

   if(consentContent[i].classList.contains('active')) {
    consentButton[i].innerHTML = '[Zwiń]';
   } else {
    consentButton[i].innerHTML = '[Pokaż]';
   }
  })

  if(consentContent[i].classList.contains('active')) {
   consentButton[i].innerHTML = '[Zwiń]';
  } else {
   consentButton[i].innerHTML = '[Pokaż]';
  }
 }
}
consentOpen()